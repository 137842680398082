import { User } from '../public-types';
const ourActivityToMifflinMap = {
  1: 1.375,
  2: 1.55,
  3: 1.725
};
/**
 * We are using this equation to calculate per-day calories intake:
 *   https://reference.medscape.com/calculator/846/mifflin-st-jeor-equation
 *   Females: (10*weight [kg]) + (6.25*height [cm]) – (5*age [years]) – 161
 *   Males: (10*weight [kg]) + (6.25*height [cm]) – (5*age [years]) + 5
 *   Guest: All family members calculations / family members = Average of fam.
 *
 *   Multiply by scale factor for activity level:
 *   Sedentary -> *1.2 => Not in use right now - 05-09-2024
 *   Lightly active -> *1.375 => Activity level 1
 *   Moderately active -> *1.55 => Activity level 2
 *   Active -> *1.725 => Activity level 3
 *   Very active ->  *1.9 => Not in use right now - 05-09-2024
 * Then we will divide that intake into 3 different meals (equally big for
 * easy-calculation purposes)
 */
export const dailyCaloriesIntake = person => {
  const settings = person.settings;
  if (!new User(person).isValid) {
    return 0;
  }
  const activityFactor = ourActivityToMifflinMap[settings.activityLevel];
  return Math.round((10 * settings.weight + 6.25 * settings.height - 5 * settings.age + (settings.sex === 'male' ? 5 : -161)) * activityFactor);
};
