<eln-avatar
  [image]="user().avatarUrl"
  [size]="70"
  [selected]="true"
  [canEdit]="true"
  (avatarChange)="avatarImageChange($event)"
  defaultIcon="person_add"
></eln-avatar>

<form class="profile-options-holder" [formGroup]="form">
  <div class="profile-option-holder">
    <mat-form-field class="profile-name-input" subscriptSizing="dynamic">
      <mat-label>Namn</mat-label>
      <input [formControl]="form.controls.name" matInput type="text"
             placeholder="För- och efternamn"/>
    </mat-form-field>
  </div>

  <div class="profile-option-holder">
    <mat-form-field class="profile-name-input" subscriptSizing="dynamic">
      <mat-label>Mejladress</mat-label>
      <input [formControl]="form.controls.email" matInput type="text"
             placeholder="sven.svensson@joshu.eu"/>
    </mat-form-field>
  </div>

  <div class="profile-option-holder">
    <span>Aktivitetsnivå</span>

    <div class="profile-activity-buttons-holder">
      <button (click)="activitySelect(1)"
              [class.active]="form.controls.settings.controls.activityLevel.value === 1">
        <img src="assets/activity_low_icon.png"
             alt="low">
        <div>Låg</div>
      </button>

      <button (click)="activitySelect(2)"
              [class.active]="form.controls.settings.controls.activityLevel.value === 2">
        <img class="taller-than-wither"
             src="assets/activity_medium_icon.png"
             alt="medium">
        <div>Medel</div>
      </button>

      <button (click)="activitySelect(3)"
              [class.active]="form.controls.settings.controls.activityLevel.value === 3">
        <img src="assets/activity_high_icon.png"
             alt="high">
        <div>Hög</div>
      </button>
    </div>
  </div>

  <div class="profile-option-holder">
    <span>Persondata</span>

    <div class="profile-personal-data-holder">
      <mat-form-field class="number-form-fields">
        <mat-label>Längd</mat-label>
        <input [formControl]="form.controls.settings.controls.height"
               matInput type="number"
               class="eln-no-flippers"/>
        <span matTextSuffix>cm</span>
      </mat-form-field>

      <mat-form-field class="number-form-fields">
        <mat-label>Ålder</mat-label>
        <input [formControl]="form.controls.settings.controls.age"
               matInput type="number"
               [max]="150"
               class="eln-no-flippers"/>
        <span matTextSuffix>år</span>
      </mat-form-field>

      <mat-form-field class="number-form-fields">
        <mat-label>Vikt</mat-label>
        <input [formControl]="form.controls.settings.controls.weight"
               matInput type="number"
               class="eln-no-flippers"/>
        <span matTextSuffix>kg</span>
      </mat-form-field>

      <mat-form-field class="number-form-fields">
        <mat-label>Kön</mat-label>
        <mat-select [formControl]="form.controls.settings.controls.sex">
          @for (sex of sexes; track $index) {
            <mat-option [value]="sex">{{ sex | translate }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="profile-calories-intake-holder">
      <span class="profile-calories-intake-amount">
        {{ dailyCaloriesIntake(user()) | number:'1.0-0':'fr' }}
        <span class="super">KCal</span>
        <span>/</span>
        <span class="sub">dag</span>
      </span>

      <mat-icon class="profile-calories-intake-info"
                #tooltip="matTooltip"
                matTooltip="Dagligt kaloriintag enligt Mifflin-St Jeor-ekvationen"
                (click)="tooltip.toggle()"
                (keydown.enter)="tooltip.toggle()"
      >help
      </mat-icon>
    </div>
  </div>
</form>
